import { useEffect } from "react";

import ReactGA from "react-ga";

import { useLocation } from "react-router-dom";

const dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const Ga = () =>
{	
	const location = useLocation();
	
	useEffect(() =>
	{
		if (dev) return;
		
		ReactGA.initialize("UA-36507831-1");
	},[]);
	
	useEffect(() =>
	{
		if (dev) return;
    	ReactGA.pageview(location.pathname);
	},[ location ]);
	
	return null;
};

export default Ga;
