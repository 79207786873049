const defaultState =
{
	loaded: false,
    size: false,
    sections: {},
    active_section: false
};

const appReducer = (state = defaultState,action) =>
{
    switch (action.type)
    {
	    case "SET_LOADED":
            return { ...state, loaded: action.payload };	    
        case "SET_SIZE":
            return { ...state, size: action.payload };
        case "SET_SECTION":
            return { ...state, sections: { ...state.sections, ...action.payload } };
        case "SET_ACTIVE_SECTION":
            return { ...state, active_section: action.payload };
        default:
            return state;
    }
};

export default appReducer;
