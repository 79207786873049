import React from "react";

import { mergeClassNames } from "helpers/classname";

import "./sass/loading.scss";

const Loading = ({ className, children, ...props }) =>
{
	className = mergeClassNames("nt-loading",className);

    return (
	<div { ...props } className={ className }>
		<div className="wrapper">
			<div className="icon" />
			{ children ? <div className="text">{ children }</div> : null }
		</div>
    </div>);
};

export default Loading;
