import React, { useState, useEffect } from "react";

import _ from "lodash";

import { NavLink } from "react-router-dom";

import AutoSize from "components/autosize";
import { mergeClassNames } from "helpers/classname";

import MainNavigation from "./navigation.jsx";
import { Chevron } from "components/logo";

const MobileHeader = ({ color }) =>
{	
	const [ open, setOpen ] = useState(false);
	const [ fade, setFade ] = useState(false);
	
	const onNav = () =>
	{
		setOpen(false);
	};
	
	const toggleMenu = (e) =>
	{
		e.preventDefault();
		setOpen(!open);
	};
	
	useEffect(() =>
	{	
		let scrollPos = 0;
		
		const _onScroll = () =>
		{
			const { top } = document.body.getBoundingClientRect();
			setFade(top < scrollPos && top < -100);
			scrollPos = top;
		};
		
		const onScroll = _.throttle(_onScroll);
	
		window.addEventListener("scroll", onScroll, { passive: true });
		_onScroll();
		
		return () =>
		{
		    window.removeEventListener("scroll", onScroll);
		};
	},[]);
	
	
	const className = mergeClassNames("mobile-logo-menu", [ color, open ? "open" : "close", fade ? "fade" : "no-fade"]);
	const mobileClassName = mergeClassNames("mobile-nav-menu", open ? "open" : "close");
	const hamburgerClassName = mergeClassNames("hamburger", open ? "open" : "close");
	
	return (
	<>
		<header className="header">
			<div className={ className }>
				<NavLink to={ "/" } onClick={ () => onNav("/") }>
					<Chevron />
					<Chevron direction="right" />

					<div className="logo-text">
						<AutoSize>
							newtype
						</AutoSize>
					</div>
				</NavLink>
				
				<a className={ hamburgerClassName } href="/" title="open navigation" onClick={ toggleMenu }>
					<i></i>
				</a>
			</div>
		</header>
		
		<MainNavigation className={ mobileClassName } open={ open } onClick={ onNav } />
	</>);
};

export default MobileHeader;