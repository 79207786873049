import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "redux/app/actions";
import { selectSections, selectActiveSection } from "redux/app/selectors";

const Active = () =>
{
    const dispatch = useDispatch();
    
	const sections = useSelector(selectSections);
	const active = useSelector(selectActiveSection);
	
	const [ location ] = useState(useLocation());
	
	useEffect(() =>
	{		
		if (active || !sections || !Object.keys(sections).length) return;
		
    	let activeKey = Object.keys(sections).find(key => sections[key].link === location.pathname);
    	
    	if (!activeKey) activeKey = Object.keys(sections)[0];
    	
    	const section = sections[activeKey];
    	window.scrollTo(0,section.element.offsetTop);
    	
    	dispatch(setActiveSection(section));	
	},[ dispatch, sections, active, location ]);
	
	return null;
};

export default Active;
