import React, { useState, useEffect } from "react";

import axios from "axios";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { mergeClassNames } from "helpers/classname";

import Button from "components/button";
import { Grid, Item } from "components/grid";
import Loading from "components/loading";

import "./sass/contact.scss";

const contactUrl = "/contact.php";

const Label = ({ name, field = {}, label, children }) =>
{
	const { focused, value, error } = field;
	
	let className = ["float-field"];
	
	if (focused) className.push("focused");
	if (value) className.push("has-value");
	if (error) className.push("invalid");
	if (value && !error) className.push("valid");
	
	let labelTxt = (error && (focused || value)) ? error : label;
	
	return (
	<div className={ className.join(" ") }>
		<label className="label" htmlFor={ name }>{ labelTxt }</label>
		<div className="field">{ children }</div>
	</div>);	
};

const ContactForm = () =>
{
	const [ token, setToken ] = useState(false);
	const [ fields, setFields ] = useState({});
	const [ loading, setLoading ] = useState(false);
	const [ success, setSuccess ] = useState(false);
	const [ error, setError ] = useState(false);
	
	const { executeRecaptcha } = useGoogleReCaptcha();
	
	useEffect(() =>
	{
		if (!executeRecaptcha) return;
		
		executeRecaptcha("contact").then((token) =>
		{
			setToken(token);
		}).catch(() => setToken(false));
		
	}, [ executeRecaptcha ]);
	
	const updateField = (name, update) =>
	{
		const updatedFields = { ...fields };
		
		if (!updatedFields[name]) updatedFields[name] = {};
		updatedFields[name] = { ...updatedFields[name], ...update };
		
		setFields(updatedFields);		
	};
	
	const validateField = (name, value = false, set = true) =>
	{
		let update = { value: value, valid: true, error: false };
		
		if (typeof value === "string") value = value.trim();
		
		if (!value || !value.length)
		{
			update.valid = false;
			
			switch (name)
			{
				case "firstName":
					update.error = "We need your first name.";	
					break;
				case "lastName":
					update.error = "We need your last name.";	
					break;
				case "email":
					update.error = "How do we contact you?";	
					break;
				case "company":
					update.valid = true;
					update.error = false;
					break;
				case "message":
					update.error = "Are you gonna leave a message?";	
					break;
				default:
					update.error = true;	
			}			
		} else
		if (name === "email")
		{
			const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;			
			if (!regex.test(String(value).toLowerCase()))
			{
				update.valid = false;
				update.error = "Invalid email.";
			}
		}
		
		if (set)
		{
			updateField(name, update);
		} else {
			return update;
		}
	};
	
	const validateFields = () =>
	{	
		const updatedFields = {};
		
		["firstName", "lastName", "email", "company", "message"].forEach((field) =>
		{
			updatedFields[field] = validateField(field,(fields[field] || {}).value,false);
		});
		
		setFields(updatedFields);
		
		return updatedFields;
	};
	
	const focusField = (name, focused = true) => 
	{
		updateField(name, { focused: focused });
	};
	
	const onSubmit = (e) =>
	{
		e.preventDefault();
		
		if (loading || success) return;
		
		const fields = validateFields();		
		const valid = !!!Object.keys(fields).find(k => fields[k].error);
		
		if (valid)
		{
			const data = new URLSearchParams();
			Object.keys(fields).forEach((field) => { data.append(field,  fields[field].value) });
			data.append("token", token);
			
			axios.post(contactUrl, data, { "Content-Type": "application/x-www-form-urlencoded", withCredentials: true }).then((response) =>
			{
				setSuccess(true);
			}).catch((error) =>
			{
				setError(true);
			}).finally(() =>
			{
				setLoading(false);			
			});
		}
	};
	
	const successMessage = (
	<div className="success-message">
		<p>Pew!</p>
		<p>
			Your message is on the way 
			and we’ll be in touch soon.
		</p>
	</div>);
	
	const errorMessage = (
	<div className="error-message">
		<p>Oof!</p>
		<p>
			Looks like the goblins ate your message. Wait a few and try again.
		</p>
	</div>);
	
	const className = ["contact-form"];
	if (loading) className.push("loading");
	if (success) className.push("success");
	if (error) className.push("error");
	
	return (
	<form className={ mergeClassNames(className) } onSubmit={ onSubmit } autoComplete="off">
		<Grid gap="default">	
			<Item span={ 24 } config={{ lg: { span: 12 } }}>
				<Label name="firstName" field={ fields.firstName } label="What's your first name?">
					<input onChange={ (e) => validateField("firstName",e.target.value) } onFocus={ () => focusField("firstName") } onBlur={ () => focusField("firstName",false) } type="text" name="firstName" id="firstName" autoComplete="off" />
				</Label>
			</Item>
			
			<Item span={ 24 } config={{ lg: { span: 12 } }}>								
				<Label name="lastName" field={ fields.lastName } label="What's your last name?">
					<input onChange={ (e) => validateField("lastName",e.target.value) } onFocus={ () => focusField("lastName") } onBlur={ () => focusField("lastName",false) } type="text" name="lastName" id="lastName" autoComplete="off" />
				</Label>
			</Item>
			
			<Item span={ 24 } config={{ lg: { span: 12 } }}>
				<Label name="email" field={ fields.email } label="What's your email?">
					<input onChange={ (e) => validateField("email",e.target.value) } onFocus={ () => focusField("email") } onBlur={ () => focusField("email",false) } type="text" name="email" id="email" autoComplete="off" />
				</Label>
			</Item>
			
			<Item span={ 24 } config={{ lg: { span: 12 } }}>
				<Label name="company" field={ fields.company } label="Who do you represent?">
					<input onChange={ (e) => validateField("company",e.target.value) } onFocus={ () => focusField("company") } onBlur={ () => focusField("company",false) } type="text" name="company" id="company" autoComplete="off" />
				</Label>
			</Item>
				
			<Item span={ 24 }>
				<Label name="message" field={ fields.message } label="We’re excited to hear from you!">
					<textarea onChange={ (e) => validateField("message",e.target.value) } onFocus={ () => focusField("message") } onBlur={ () => focusField("message",false) } type="text" name="message" id="message" rows={ 6 } cols={ 8 } autoComplete="off"></textarea>
				</Label>
			</Item>
			
			<Item className="text-ctr" span={ 24 }>								
				<Button className="fs-n" type="submit">
					Let’s go!
				</Button>
			</Item>
		</Grid>
		
		{ success ? successMessage : null }
		{ error ? errorMessage : null }
		{ loading ? <Loading /> : null }
	</form>);
};

export default ContactForm;
