import React from "react";

import DesignIcon from "media/services/design.svg";
import DevelopmentIcon from "media/services/development.svg";
import MigrationIcon from "media/services/migration.svg";
import StuffIcon from "media/services/stuff.svg";

import HubSpotLogo from "media/friends/hubspot.svg";
import MarketoLogo from "media/friends/marketo.jpg";
import PardotLogo from "media/friends/pardot.png";

import { useSelector } from "react-redux";
import { selectDesktop } from "redux/app/selectors";

import { Grid, Item } from "components/grid";
import { SectionTitle, SectionSubTitle, SectionContent } from "components/section";

import "./sass/services.scss";

const Services = () =>
{
	const isDesktop = useSelector(selectDesktop);
	
	const contentCol = { md: { span: 12 }, lg: { span: 8 } };
	
	return (
	<div className="content">
		<SectionTitle subTitle="So what do you do?">Our Services</SectionTitle>
		
		<Grid gap="large">	
			<Item span={ 24 } config={ contentCol }>
				<SectionSubTitle icon={ DesignIcon }>Design</SectionSubTitle>
				
				<SectionContent>			
					<p>We speak in <strong className="purple">&lt;pixels&gt;</strong> so you don’t have to. You want your form templates, email tem&shy;plates, and landing page templates to be consistent with each other and on–brand. We specialize in seamless brand templates.</p>
	
					<p>Remember that amazing email that came through your inbox that you wish was yours? Now it can be. You see cool forms and landing pages all the time, and you want to flex, too. No worries. If you see something you like somewhere else, we can emulate it for you.</p>
					
					<p>Or maybe you’re feeling adventurous and want us to design you something special? We got you. Just tell us what you want and we can come up with something innovative and unique to really showcase your brand.</p>
				</SectionContent>
			</Item>
			
			<Item span={ 24 } config={ contentCol }>
				<SectionSubTitle icon={ DevelopmentIcon }>Development</SectionSubTitle>
			
				<SectionContent>
					<p>We speak in <strong className="purple">&lt;code&gt;</strong> so you don’t have to. Oh noes! You’ve just set up your marketing auto&shy;mation platform and had your designer bless you with some of their best work yet. But who’s going to turn those pixels into code? &#9995;</p>
					
					<p>We understand the frustrations of working with default templates. But it doesn’t have to be that way. Don’t let yourself be limited by the tools you were given. Let your imagination run wild and we can make your dreams come true. Hyperbole aside, we can pretty much build anything you want, no matter how wacky*.</p>
					
					<p className="small">* Wacky isn’t always better, so if we see you going off the rails, we will do our best to reign you back in.</p>
				</SectionContent>
			</Item>
			
			<Item span={ 24 } config={ contentCol }>
				<SectionSubTitle icon={ MigrationIcon }>Migrations</SectionSubTitle>
			
				<SectionContent>
					<p>We do the <strong className="purple">&lt;heavy lifting&gt;</strong> so you don’t have to. Damn, your assets weigh a ton! You’ve decided to make the switch from Service A to Service B. How are you going to get all of those assets converted over to make them compatible with your new service?</p>
					
					<p>If you’ve been with your current service for a while, you could be sitting on quite the stockpile of assets. It can be a huge pain in the ass and major headache trying to move that mountain from one side to the other. Is that really what you want? Absolutely not. So sit back and relax, and let us deal with the headache and ass pain.</p>
				</SectionContent>
			</Item>
						
			<Item span={ 24 } config={{ ...contentCol, lg: { span: 12 } }}>
				<SectionSubTitle icon={ isDesktop ? null : StuffIcon }>Other Stuff</SectionSubTitle>
				
				<SectionContent>
					<ul className="shuriken">
						<li>Branding</li>
						<li>Graphic Design</li>
						<li>Ideation</li>
						<li>UI/UX Design</li>
						<li>Motion Graphics</li>
						<li>Video</li>
						
						<li>Information Architecture	</li>
						<li>Website Development</li>
						<li>Magento</li>
						<li>WordPress</li>
						<li>React</li>
						<li>Illustration</li>
						
						<li>Website Hosting / Maintenance</li>
					</ul>
				</SectionContent>
			</Item>
			
			<Item span={ 24 } config={{ lg: { span: 12 } }}>
				<SectionSubTitle>Our Friends</SectionSubTitle>
				<Grid className="friend-logos" gap="default">	
					<Item span={ 24 } config={{ md: { span: 8 }, lg: { span: 8 } }}>
						<img className="friend-logo small hubspot" src={ HubSpotLogo } alt="Logo for HubSpot" />
					</Item>
					
					<Item span={ 24 } config={{ md: { span: 8 }, lg: { span: 8 } }}>
						<img className="friend-logo pardot" src={ PardotLogo } alt="Logo for Salesforce and Pardot" />
					</Item>
					
					<Item span={ 24 } config={{ md: { span: 8 }, lg: { span: 8 } }}>
						<img className="friend-logo small marketo" src={ MarketoLogo } alt="Logo for Marketo" />
					</Item>
				</Grid>
			</Item>
		</Grid>
	</div>);
};

export default Services;
