import _ from "lodash";

const defaultConfig =
{
	className: "fade-effect",
	activeClassName: "do-fade",
	offset: 0,
	reset: "both",
	direction: "vertical",
	selector: "h1, h2, h3, h4, h5, h6, .section-title > *, .section-sub-title > *, .section-content > *, .section-content > * > li, .hero-content > *, .footer > *, .friend-logo, .client-logo, .float-field, .nt-button"
};

function get_postion(element)
{	
	var rect = element.getBoundingClientRect();
	
	return {
		x: rect.left || rect.x,
		y: rect.top || rect.y,
		w: rect.width,
		h: rect.height
	};
}

export const fadeEffect = (parent = document, config = {}) =>
{
	config = { ...defaultConfig, ...config };
	
	const { className, activeClassName, offset, reset, direction, selector } = config;
	
	if (parent.current) parent = parent.current;
	
	const children = parent.querySelectorAll(selector);
	children.forEach(c => c.classList.add(className));
	
	let init = true;
	
	const _doFade = () =>
	{		
		let w = window.innerWidth,
			h = window.innerHeight;
		
		const { top, bottom, height } = parent.getBoundingClientRect();
		const offsetHeight = h * 1.5;		
		const active = ((top > 0 && top < offsetHeight) || (bottom > 0 && bottom < offsetHeight) || (top < 0 && height + top > 0));
		
		if (!active && !init) return;
		
		children.forEach(( child ) =>
		{
		
			let add = false,
				remove = false,
				pos = get_postion(child);
			
			pos.x += offset;
			pos.y += offset;
			
			if((direction === "vertical" && pos.y + pos.h >= -1 && pos.y - h <= pos.h) || (direction === "horizontal" && pos.x + pos.w >= -1 && pos.x - w <= pos.w)) add = true;
						
			switch(reset)
			{
				case "before":
					if((direction === "vertical" && pos.y + pos.h < -1) || (direction === "horizontal" && pos.x + pos.w < -1)) remove = true;	
					break;
				case "after":
					if((direction === "vertical" && pos.y > h) || (direction === "horizontal" &&  pos.x > w)) remove = true;
					break;
				case "both":
					if((direction === "vertical" && (pos.y + pos.h < -1 || pos.y > h)) || (direction === "horizontal" && (pos.x + pos.w < -1 || pos.x > w))) remove = true;
					break;
				default:
					remove = false;
			}
				
			if(add && !remove) 
			{
				child.classList.add(activeClassName);
			} else
			if(remove) 
			{
				child.classList.remove(activeClassName);
			}
		});
		
		if (init) init = false;
		
	};
	const doFade = _.debounce(_doFade);

	window.addEventListener("resize", doFade, { passive: true });
	window.addEventListener("scroll", doFade, { passive: true });
	_doFade();
	
	return () =>
	{
	    window.removeEventListener("resize", doFade);
	    window.removeEventListener("scroll", doFade);
	};
	
};
