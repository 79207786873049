import { createSelector } from "reselect";
export const selectApp = state => state.app;

export const selectLoaded = createSelector([selectApp], app => app.loaded);

export const selectSize = createSelector([selectApp], app => app.size);

export const selectMobile = createSelector([selectSize], size => ["xs","sm"].includes(size));
export const selectTablet = createSelector([selectSize], size => ["md"].includes(size));
export const selectDesktop = createSelector([selectSize], size => ["lg","xl","xxl"].includes(size));

export const selectXS = createSelector([selectSize], size => size === "xs");
export const selectSM = createSelector([selectSize], size => size === "sm");
export const selectMD = createSelector([selectSize], size => size === "md");
export const selectLG = createSelector([selectSize], size => size === "lg");
export const selectXL = createSelector([selectSize], size => size === "xl");
export const selectXXL = createSelector([selectSize], size => size === "xxl");


export const selectSections = createSelector([selectApp], app => app.sections);
export const selectActiveSection = createSelector([selectApp], app => app.active_section);
