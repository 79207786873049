import React, { useEffect, useState } from "react";

import { mergeClassNames } from "helpers/classname";

const itemClass = (size, type = "span", prefix = false) =>
{	
	const className = type + "-" + size;
	
	return prefix ? prefix + "-" + className : className;
};

const Item = ({ className, span = false, offsetLeft = false, offsetRight = false, grow = false, noGrow = false, config = false, children, ...props }) =>
{
	const [ itemClasses, setItemClasses ] = useState("item","span");
	
	useEffect(() =>
	{
		const itemClasses = ["item"];
		
		const sizeClasses = ({ span, offsetLeft, offsetRight, grow, noGrow }, prefix) =>
		{
			if (span) itemClasses.push(itemClass(span,"span",prefix));
			if (offsetLeft) itemClasses.push(itemClass(offsetLeft,"offset-left",prefix));
			if (offsetRight) itemClasses.push(itemClass(offsetRight,"offset-right",prefix));
			if (grow) itemClasses.push(prefix + "-grow");
			if (noGrow) itemClasses.push(prefix + "-no-grow");
		};
		
		if (span) itemClasses.push(itemClass(span,"span"));
		if (offsetLeft) itemClasses.push(itemClass(offsetLeft,"offset-left"));
		if (offsetRight) itemClasses.push(itemClass(offsetRight,"offset-right"));
		if (grow) itemClasses.push("grow");
		if (noGrow) itemClasses.push("no-grow");
		
		if(config && typeof config === "object") Object.keys(config).forEach(key => sizeClasses(config[key],key));
		
		setItemClasses(mergeClassNames(itemClasses,className));
		
	},[ className, span, offsetLeft, offsetRight, grow, noGrow, config ]);
	
    return (
	<div { ...props } className={ itemClasses }>
		{ children }
    </div>);
};

export default Item;
