export const classNameString = (className) =>
{
    if(Array.isArray(className)) className = className.filter(c => c).join(" ");

    return className;
};

export const classNameArray = (className = []) =>
{
    if(!Array.isArray(className) && typeof className === "string") className = className.split(" ") || [];
    if(!Array.isArray(className)) className = [];

    return className;
};

export const mergeClassNames = (a = [], b = []) =>
{
	const className = [ ...classNameArray(a), ...classNameArray(b)];
	return classNameString(className);	
};