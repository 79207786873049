export const externalImport = (url,type = "js") =>
{
    return new Promise((resolve, reject) =>
    {
        let tag = document.querySelector('[src="' + url + '"]') || document.querySelector('[href="' + url + '"]');

        const add_listeners = (tag) =>
        {
            tag.addEventListener('load',() => resolve_loading(tag), false);
            tag.addEventListener('error',() => resolve_error(tag), false);
        };

        const resolve_loading = (tag) =>
        {
            tag.removeEventListener('load',() => resolve_loading(tag), false);
            tag.removeEventListener('error',() => resolve_error(), false);

            tag.setAttribute('loaded',true);

            resolve(tag);
        };

        const resolve_error = (tag) =>
        {
            tag.removeEventListener('load',() => resolve_loading(tag), false);
            tag.removeEventListener('error',() => resolve_error(), false);

            tag.remove();

            reject();
        };

        if(tag)
        {
            if(tag.hasAttribute('loaded'))
            {
                resolve(tag);
            } else {
                add_listeners(tag);
            }
        } else {
	        switch(type)
	        {
		        case "js":
	                tag = document.createElement('script');
	                tag.type = 'text/javascript';
	                tag.src = url;
                
		        	break;
		        case "css":
	                tag = document.createElement('link');
	                tag.rel = 'stylesheet';
	                tag.type = 'text/css';
	                tag.href = url;
	                
		        	break;
		        default:
		        	return;
	        }

            add_listeners(tag);

            document.head.appendChild(tag);
        }
    });
};
