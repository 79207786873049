import React, { useRef } from "react";

import { mergeClassNames } from "helpers/classname";

import AutoSize from "components/autosize";
import Chevron from "./chevron";

import "./sass/logo.scss";

const Logo = ({ className, text = false, ...props }) =>
{
	const logo = useRef();
	
	className = mergeClassNames(["nt-html-logo"],className);

    return (
    <div { ...props } ref={ logo } className={ className }>
    	<div className="chevrons">
			<AutoSize min={ 20 } lines={ 2 } wraper={ logo }>
				<div className="gradient">	
					<Chevron />
					<Chevron direction="right" />
				</div>
		    	<div className="shadow">
					<Chevron />
					<Chevron direction="right" />
				</div>
				{ /* <span className="registered">&reg;</span> */ }
			</AutoSize>
		</div>
		{ text ?
		<div className="text">
			<AutoSize min={ 20 } wraper={ logo }>
				<div className="frank">newtype</div>
			</AutoSize>
		</div> : null }
    </div>);
};

export default Logo;
