import React, { useRef, useEffect } from "react";

import { mergeClassNames } from "helpers/classname";

import { dotsCanvas } from "./scripts/dots.js"
import "./sass/dots.scss";

const Dots = ({ className, parent, ...props }) =>
{	
	className = mergeClassNames("effect-dots", className);
	
	const background = useRef();
	const foreground = useRef();
	
	useEffect(() =>
	{
		const bCanvas = background.current;
		const fCanvas = foreground.current;
		
		if (!bCanvas || !fCanvas) return;
		
		return dotsCanvas(parent, bCanvas, fCanvas);		
	},[ parent, background, foreground ]);
	
	return (
	<>
		<canvas ref={ background } className={ className } { ...props } />
		<canvas ref={ foreground } className={ className } { ...props } />
	</>);
};

export default Dots;