const defaultState =
{
    viewed: false
};

const splashReducer = (state = defaultState,action) =>
{
    switch (action.type)
    {
        case "SET_VIEWED":
            return { ...state, viewed: action.payload };
        default:
            return state;
    }
};

export default splashReducer;
