import { useEffect, useState } from "react";

import _ from "lodash";

import Home from "pages/home";
import Lost from "pages/lost";

export const routes = 
[
	{
        path: [ "/" ],
        component: <Home />,
        title: [ "newtype" ],
        link: "newtype",
        exact: true,
        page: "home"
    },
    {
        path: [ "/services" ],
        component: <Home />,
        title: [ "newtype" ],
        link: "services",
        exact: true,
        page: "home"
    },
    {
        path: [ "/about" ],
        component: <Home />,
        title: [ "newtype" ],
        link: "about",
        exact: true,
        page: "home"
    },
    {
        path: [ "/clients" ],
        component: <Home />,
        title: [ "newtype" ],
        link: "clients",
        exact: true,
        page: "home"
    },
    {
        path: [ "/contact" ],
        component: <Home />,
        title: [ "newtype" ],
        link: "contact",
        exact: true,
        page: "home"
    },
    {
        path: [ "*" ],
        component: <Lost />,
        title: [ "newtype", "404" ],
        exact: true
    }	
];

const combinedRoutes = [];

 routes.forEach((route) =>
 {
	 if (route.page)
	 { 
		 if (combinedRoutes.find(({ page }) => route.page === page)) return;
		 const path = routes.filter(({ page }) => route.page === page).map(({ path }) => path);
		 combinedRoutes.push({ ...route, path: _.flatten(path) });
	 } else {
		 combinedRoutes.push(route);
	 }
 });
 
export { combinedRoutes };

export const links = routes.filter(r => r.link);

export const Page = ({ title, children }) =>
{
	const [ pageTitle, setPageTitle ] = useState("newtype");
	const [ hasFocus, setHasFocus ] = useState(false);
	
	useEffect(() =>
	{
		const checkFocus = () => setHasFocus(document.visibilityState === "visible" || document.hasFocus());
		document.addEventListener("visibilitychange", checkFocus);
		
		checkFocus();
		
		return () => document.removeEventListener("visibilitychange", checkFocus);
	},[]);
	
	useEffect(() =>
	{
		if (!title) return;
	
		let pageTitle = false;
		
		if(typeof title === "string") pageTitle = title;
		if(Array.isArray(title)) pageTitle = title.join(" / ");
		
		if (pageTitle) setPageTitle(title);
	},[ title ]);
	
	useEffect(() =>
	{
		document.title = hasFocus ? 	pageTitle : "newtype";
	},[ pageTitle, hasFocus ]);

    return children;
};
