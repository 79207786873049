import React, { useRef } from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { useSelector } from "react-redux";
import { selectMobile } from "redux/app/selectors";

import { Dots } from "components/effects";

import { Grid, Item } from "components/grid";
import { SectionTitle, SectionContent } from "components/section";
import Footer from "components/footer";

import Form from "./form.jsx";

import "./sass/contact.scss";

const Contact = () =>
{	
	const bkg = useRef();
	
	const isMobile = useSelector(selectMobile);
	
	return (
	<>
		<div ref={ bkg } className="bkg"><Dots parent={ bkg } /></div>
		<div className="content">
			<SectionTitle light={ true } subTitle="Let’s get to work!">Contact Us</SectionTitle>
			<Grid gap="default" gaps={{ lg: "large" }}>			
				<Item span={ 24 } config={{ md: { span: 9 }, lg: { span: 8 } }}>
					<SectionContent>			
						<p>
							If you’re looking to start a relationship with a creative shop that consistently delivers kick-ass work and frequent shenanigans, then you need to be filling out the form { !isMobile ? "to the right" : "below" }.
						</p>
						<p>
							<strong>newtype</strong> is open 24/7! <br />
							(unless we’re closed)
						</p>
					</SectionContent>
				</Item>
				
				<Item span={ 24 } grow={ true } config={{ md: { span: 6 }, lg: { span: 16 } }}>
					<GoogleReCaptchaProvider reCaptchaKey="6LekTV4bAAAAAGkxwAQmLqLNlDjNHKRPwya-KQ9w">
						<Form />
					</GoogleReCaptchaProvider>
				</Item>
				
				<Item span={ 24 }>
					<Footer />
				</Item>
			</Grid>
		</div>
	</>);
};

export default Contact;
