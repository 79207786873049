import React, { useRef, useEffect } from "react";

import { useDispatch } from "react-redux";

import { setSection } from "redux/app/actions";

import { mergeClassNames } from "helpers/classname";
import { Fade } from "components/effects";

import "./sass/block.scss";

const SectionBlock = ({ className, id, color = "dark", link = false, fade = true, fadeProps = {}, setRef, children, ...props }) =>
{
	className = mergeClassNames(["nt-section",color],className);
	
    const dispatch = useDispatch();    
	const sectionRef = useRef();
	
	useEffect(() =>
	{
		if (setRef) setRef(sectionRef);
	},[ setRef, sectionRef ]);
	
	useEffect(() =>
	{
		const element = sectionRef.current;
		
		if (!element || !id) return;
			
		dispatch(setSection({ [id]: { id: id, link: link, color: color, element: element } }));
	},[ dispatch, sectionRef, color, id, link ]);

    return (
	<div { ...props } ref={ sectionRef } id={ id } className={ className }>
		{ fade ? <Fade parent={ sectionRef } { ...fadeProps }>{ children }</Fade> : children }
    </div>);
};

export default SectionBlock;
