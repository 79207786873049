import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { store, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import App from "./App";

import "styles/default.scss";

const basename = process.env.REACT_APP_BASENAME || "/";
const history = createBrowserHistory({ basename: basename });

const newtype = (
<Provider store={ store }>
    <Router history={ history } basename={ basename }>
        <PersistGate persistor={ persistor }>
            <App />
        </PersistGate>
    </Router>
</Provider>);

ReactDOM.render(newtype, document.getElementById("newtype-app"));
