import React, { useEffect, useRef } from "react";

import _ from "lodash";

import { useSelector } from "react-redux";
import { selectLoaded } from "redux/app/selectors";

import { mergeClassNames } from "helpers/classname";

import "./sass/autosize.scss";

const AutoSize = ({ lines = 1, min = 10, max = 999, setFontSize, className, wraper, children, ...props }) =>
{
	const content = useRef();
	const loaded = useSelector(selectLoaded);
	
	className = mergeClassNames("nt-autosize",className);

	useEffect(() =>
	{
		const { current } = content;
		
		if (!current || !loaded) return;
				
		let active = true;
		
		let _min = parseFloat(min) || 1;
		let _max = parseFloat(max) || 999;
		let _wrap = wraper ? wraper.current : false;
		
		const _calcFontSize = (e) =>
		{	
			current.style.fontSize = _min + "px";
			
			let check = true;
			let s = _min;
			let bcr;			
			let inc = 100;
			
			const wcr = _wrap ? _wrap.getBoundingClientRect() : false;
			
			while (check && active)
			{
				if (!active) return;
				
				current.style.fontSize = s + "px";
				bcr = current.getBoundingClientRect();

				if(bcr.height / s / 2 < lines && (!wcr || bcr.width <= wcr.width) && s < _max)
				{
					s += inc;
				} else {
					s -= inc;
					
					if (s < _min)
					{
						check = false;
						s = _min;
					} else
					if (inc === 1) 
					{
						check = false;
					} else {
						inc *= 0.1;
					}
				}
			}
			
			if (active) 
			{
				current.style.fontSize = s + "px";
				if (setFontSize) setFontSize(s);
			}
		};
		
		const calcFontSize = _.debounce(_calcFontSize);
		window.addEventListener("resize", calcFontSize);
		
		_calcFontSize();
		setTimeout(_calcFontSize,500);
		
		return () => 
		{
			active = false;
			window.removeEventListener("resize", calcFontSize);
		}
	},[ content, lines, min, max, wraper, setFontSize, loaded ]);	

    return (
    <span ref={ content } className={ className } { ...props }>
    	{ children }
    </span>);
};

export default AutoSize;
