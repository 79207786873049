import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { createStateSyncMiddleware } from "redux-state-sync"

import rootReducer from "redux/root-reducer";

// Please read - https://www.npmjs.com/package/redux-state-sync
const predicate_includes = ["_USER"];
const predicate_config = {predicate: action => predicate_includes.some(predicate => action.type.includes(predicate))};

let middlewares = [thunk, createStateSyncMiddleware(predicate_config)];

if(process.env.NODE_ENV === "development" && false) middlewares = [ ...middlewares, logger ]

export const store = createStore(rootReducer,applyMiddleware( ...middlewares ));
export const persistor = persistStore(store);
