import React from "react";

import { mergeClassNames } from "helpers/classname";

import "./sass/hero.scss";

const Hero = ({ className, background = null, left = null, center = null, right = null }) =>
{	
	className = mergeClassNames("nt-hero", className);
	
	return (
	<div className={ className }>
		{ background ? <div className="bkg">{ background }</div> : null }
		
		<div className="wrapper">
			<div className="content">
				{ left ? <div className="left"><div className="hero-content">{ left }</div></div> : null }
				{ center ? <div className="center"><div className="hero-content">{ center }</div></div> : null }
				{ right ? <div className="right"><div className="hero-content">{ right }</div></div> : null }
			</div>
		</div>
	</div>);
};

export default Hero;