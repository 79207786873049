import React, { useEffect, useState } from "react";

import { mergeClassNames } from "helpers/classname";

import "./sass/grid.scss";

const gapClass = (size,prefix = "") =>
{
	if (typeof size !== "string") return null;
	
	let className;

	switch (size)
	{
		case "xs":
		case "extrasmall":
			className = prefix + "gap-s";
			break;
		case "s":
		case "sm":
		case "small":
			className = prefix + "gap-s";
			break;
		case "d":
		case "dm":
		case "default":
			className =  prefix + "gap-d";
			break;
		case "l":
		case "lrg":
		case "large":
			className =  prefix + "gap-l";
			break;
		case "j":
		case "justify":
			className =  prefix + "gap-justify";
			break;
		default:
			className =  null;
	}
	
	return className;
};

const Grid = ({ className, gap = false, gaps = false, children, ...props }) =>
{
	const [ gridClasses, setGridClasses ] = useState("nt-grid");
	
	useEffect(() =>
	{
		const gridClasses = ["nt-grid"];
		
		if (gap) gridClasses.push(gapClass(gap));
		
		if(gaps && typeof gaps === "object")
		{
			if (gaps.sm) gridClasses.push(gapClass(gaps.sm,"sm-"));
			if (gaps.md) gridClasses.push(gapClass(gaps.md,"md-"));
			if (gaps.lg) gridClasses.push(gapClass(gaps.lg,"lg-"));
			if (gaps.xl) gridClasses.push(gapClass(gaps.xl,"xl-"));
		}
		
		setGridClasses(mergeClassNames(gridClasses,className));
	},[ className, gap, gaps ]);

    return (
	<div { ...props } className={ gridClasses }>
		{ children }
    </div>);
};

export default Grid;
