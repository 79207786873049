import React, { useState, useEffect } from "react";

import ReactPlayer from "react-player";

import { useSelector } from "react-redux";
import { selectMobile } from "redux/app/selectors";

import Button from "components/button";
import AutoSize from "components/autosize";
import Hero from "components/hero";

import { Logo } from "components/logo";

const Intro = () =>
{	
	const [ fontSize, setFontSize ] = useState(10);
	const [ videoProps, setVideoProps ] = useState({});
	
	const isMobile = useSelector(selectMobile);
	
	useEffect(() =>
	{
		const videoProps =
		{
			url: "https://newty-1.wistia.com/medias/817y8chfqj",
			className: "video",
			width: "100%",
			height: "100%",
			playsinline: true,
			volume: 0,
			muted: true,
			controls: false,
			loop: true,
			playing: true,
			config:
			{
				"wistia":
				{
					options: 
					{
						autoPlay: true,
						silentAutoPlay: true,
						controlsVisibleOnLoad: false,
						endVideoBehavior: "loop",
						fitStrategy: "cover",
						muted: true,
						playSuspendedOffScreen: true,
						playsinline: true,
						doNotTrack: true,
						seo: false,
						settingsControl: false,
						wmode: "transparent"
					}
				}
			}
	    };
	    
	    setVideoProps(videoProps);
	    
	    return () =>
	    {
		    setVideoProps({});
	    };
	},[]);
    
    const desktopHeadline = (
	<h1 className="headline">
		<AutoSize><strong>Creative Services</strong> <span style={{ fontSize: "0.75em" }}>for</span></AutoSize>
		<AutoSize><strong>Marketing Automation</strong></AutoSize>
	</h1>);
	
    const mobileHeadline = (
	<h1 className="headline">
		<AutoSize setFontSize={ setFontSize }><strong>Creative</strong></AutoSize>
		<AutoSize><strong>Services</strong></AutoSize>
		<AutoSize max={ fontSize * 0.75 }>for</AutoSize>
		<AutoSize><strong>Marketing</strong></AutoSize>
		<AutoSize><strong>Automation</strong></AutoSize>
	</h1>);
	
	const background = <ReactPlayer { ...videoProps } />;
	const left = <Logo />;
	const right = (
	<>
		{ isMobile ? mobileHeadline : desktopHeadline }	
		<p>We do the work, { isMobile ? <br /> : null } so you don’t have to.</p>
		<Button type="link" path="/contact" className="fs-n">Yes, Please</Button>
	</>);
	
	
	return <Hero background={ background } left={ left } right={ right } />;
};

export default Intro;
