import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import localStorage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";
import { withReduxStateSync } from "redux-state-sync";

import appReducer from "redux/app/reducer";
import splashReducer from "redux/splash/reducer";

const session_base_key = "nt-v-0.0.1";

const blacklistAll = ["app"];
const whitelistLocal = ["splash"];

const sessionConfig = 
{
    key: session_base_key + "_session",
    storage: sessionStorage,
    whitelist: [],
    blacklist: [ ...blacklistAll, ...whitelistLocal ]
};

const localConfig = 
{
    key: session_base_key + "_local",
    storage: localStorage,
    whitelist: [ ...whitelistLocal ],
    blacklist: [ ...blacklistAll ]
};

const localReducer = combineReducers(
{ 
	splash: splashReducer
});

const rootReducer = combineReducers(
{
    local: persistReducer(localConfig, localReducer),
    app: appReducer
});

export default persistReducer(sessionConfig, withReduxStateSync(rootReducer));
