import React from "react";

import { mergeClassNames } from "helpers/classname";

import "./sass/subtitle.scss";

const headings = { h1: "h1", h2: "h2", h3: "h3", h4: "h4", h5: "h5", h6: "h6" };

const SectionSubTitle = ({ className, icon, type = "h3", children, ...props }) =>
{
	const alt = "Icon for - " + (typeof children === "string" ? children : "section");
	
	className = mergeClassNames("section-sub-title",className);

    return (
	<div { ...props } className={ className }>
		{ React.createElement(headings[type] || headings.h1, { className: "title" }, children) }
		{ icon ? <img className="icon" src={ icon } alt={ alt } /> : null }
    </div>);
};

export default SectionSubTitle;
