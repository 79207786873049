import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setLoaded } from "redux/app/actions";
import { selectLoaded, selectSize, selectActiveSection } from "redux/app/selectors";

import LoadingIcon from "components/loading";

import { mergeClassNames } from "helpers/classname";
import { externalImport } from "helpers/import";

import Active from "./active.jsx";
import Ga from "./ga.jsx";
import Size from "./size.jsx";
import Scroller from "./scroller.jsx";

import "./sass/loading.scss";

const siteFonts = ['Exo', 'Jura', 'frank-new'];

const allFonts = () =>
{
	try {
		let { fonts } = document;
		const _fonts = fonts.entries();
		
		let loaded = [];
		let done = false;

		console.log('---a')
		
		while (!done) 
		{
			const font = _fonts.next();
			
			if (!font.done) 
			{
				const family = (Array.isArray(font.value) ? font.value[0].family : font.value.family).replace(/[^a-zA-Z0-9-]+/g,"");
				if (!loaded.includes(family)) loaded.push(family);
			} else {
				done = font.done;
			}
		}
		
		return loaded;
	} catch (e)
	{
		console.log('Font Check Error',e);
		return siteFonts;
	}
}

const Loading = ({ children }) =>
{
    const dispatch = useDispatch();   
    
	const [ googleFontsLoaded, setGoogleFontsLoaded ] = useState(false);
	const [ typekitFontsLoaded, setTypekitFontsLoaded ] = useState(false);
	const [ fontLoaded, setFontLoaded ] = useState(false);
	const [ initContent, setInitContent ] = useState(false);
	const [ animationFinished, setAnimationFinished ] = useState(false);
	
	const loaded = useSelector(selectLoaded);
	const size = useSelector(selectSize);
	const active = useSelector(selectActiveSection);
	
	const className = mergeClassNames("nt-loading-screen", loaded && active ? "loaded" : "not-loaded" );
	
	useEffect(() =>
	{		
		// Load the google fonts, gates content to help effects
		externalImport("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,500;0,700;0,800;1,500;1,700;1,800&family=Jura:wght@400;700&display=swap","css").then(() =>
		{
			setGoogleFontsLoaded(true);
		});
	},[]);
	
	useEffect(() =>
	{		
		// Load typekit fonts
		externalImport("https://use.typekit.net/ggn4xxl.css","css").then(() =>
		{
			setTypekitFontsLoaded(true);
		});
	},[]);
	
	useEffect(() =>
	{		
		setFontLoaded(googleFontsLoaded && typekitFontsLoaded);
	},[ googleFontsLoaded, typekitFontsLoaded ]);
	
	useEffect(() =>
	{	
		const init = fontLoaded && size;
		if (!init) return;
		
		const checkFonts = () =>
		{			
			const loadedFonts = allFonts();
			const missingFont = siteFonts.find(f => !loadedFonts.includes(f));			
			
			console.log('allFonts',loadedFonts);
			console.log('missingFont',missingFont);

			if (!missingFont)
			{
				setInitContent(init);
				setTimeout(() => dispatch(setLoaded(fontLoaded)), 500);
			} else {
				setTimeout(checkFonts, 250);
			}
		};
		
		checkFonts();
	},[ dispatch, fontLoaded, size ]);
	
	useEffect(() =>
	{	
		if (!loaded) return false;
		
		const doAnimationFinished = setTimeout(() => setAnimationFinished(false), 400);
		return () => clearTimeout(doAnimationFinished);
	},[ loaded ]);
	
	return (
	<>	
		<Ga />
		<Size />
		{ initContent ? children : <><span className="exo">exo</span><span className="jura">jura</span><span className="frank">frank</span></> }
		{ initContent && active ? <Scroller /> : null }
		{ initContent ? <Active /> : null }
		{ !animationFinished ? <div className={ className }><LoadingIcon /></div> : null }
	</>)
};

export default Loading;
