import React from "react";

import { Switch, Route } from "react-router-dom";
import { combinedRoutes, Page } from "./routes";

import Header from "components/header";

import { Loading } from "components/app";

export const App = () =>
{	
	const appPage = ({ title, component, component_only, ...props }) =>
	{
		return (
		<Route key={ props.path } { ...props }>
			{ component_only ? component : <Page title={ title }>{ component }</Page> }
		</Route>);
	};
	
	return (
	<Loading>
		<section id="main">
			<Header />
		
			<Switch>
				{ combinedRoutes.map(appPage) }		
			</Switch>
		</section>
	</Loading>);
};

export default App;
