const defaultConfig =
{
	gap: 30,
	size: 
	{
		base: 1,
		min: 0,
		max: 8,
		growth: 0.5,
		decay: 0.01
	},
	mouse:
	{
		radius: 300	
	},
	color:
	{
		background: "#1C1C1C",
		active: "rgba(135,5,169,1)",
		inactive: "rgba(72,29,83,1)"
	}
};

export const dotsCanvas = (parent, background, foreground, config = {}) =>
{
	if (!parent) return;
	
	config = { ...defaultConfig, ...config };
	
	if (parent.current) parent = parent.current;
	
	const bCtx = background.getContext("2d");
	const fCtx = foreground.getContext("2d");
	
	const getSize = () =>
	{
		const size = parent.getBoundingClientRect();
		
		background.width = size.width;
		background.height = size.height;
		
		foreground.width = size.width;
		foreground.height = size.height;
		
		return size;
	};
	
	const getOffset = () =>
	{
		var bRect = document.body.getBoundingClientRect();
		var pRect = parent.getBoundingClientRect();
		
		return { left: (pRect.left - bRect.left), top: (pRect.top - bRect.top) };
	}
	
	let size = getSize();
	let renderBackground = true;
	let active = true;
	let dots = [];
	
	const onMouseMove = (e) =>
	{
		let offset = getOffset();
		
		const x = Math.floor(e.pageX - offset.left);
		const y = Math.floor(e.pageY - offset.top);
		
		activeDots(x,y);
	};
	
	const onTouchMove = (e) =>
	{
		let offset = getOffset();
	
		const x = Math.floor(e.changedTouches[0].pageX - offset.left);
		const y = Math.floor(e.changedTouches[0].pageY - offset.top);
		
		activeDots(x,y);			
	};
	
	const onResize = () =>
	{
		size = getSize();
		dots = [];		
		renderBackground = true;	
	};
	
	const newDot = (col,row) =>
	{
		const { gap, size } = config;
		const offset = gap / 2;
		
		let x = col * gap - offset;
		let y = row * gap - offset;
		
		if (row % 2) x += offset;
		
		if (!dots[x]) dots[x] = [];
		
		dots[x][y] = { size: size.min, active: false, x: x, y: y };
	};
	
	const createDots = () =>
	{
		if (dots.length) return;
		
		const { gap } = config;
		
		const cols = Math.floor(size.width / gap) + 2;
		const rows = Math.floor(size.height / gap) + 2;
		
		let col,row;
		
		for (col = 0; col < cols; col++)
		{
			for (row = 0; row < rows; row++) newDot(col,row);
		}
	};
	
	const activeDots = (mx,my) =>
	{	
		const { mouse, size } = config;
		const { radius } = mouse;
		const { min, max } = size;
		
		let x,y,d,dot;
		
		
		dots.forEach((c) => c.forEach((dot) =>
		{
			dot.target = min;
		}));
		
		for (x = mx - radius; x < mx + radius; x++)
		{
			for (y = my - radius; y < my + radius; y++)
			{
				if (dots[x] && dots[x][y])
				{
					dot = dots[x][y];
					
					d = Math.sqrt((mx - x) ** 2 + (my - y) ** 2);
					
					dot.active = true;
					dot.target  = (max * (radius - d)/radius);
				}
			}
		}
	};
	
	const drawDot = (dot) =>
	{
		const { size } = config;
		const { base, min, max, decay, growth } = size;
		
		if (renderBackground)
		{
			bCtx.beginPath();
			bCtx.arc(dot.x, dot.y, base, 0, 2 * Math.PI, false);
			bCtx.fill();
		}
		
		if (dot.active)
		{
			if (dot.size <= dot.target)
			{
				dot.size += growth;
				
				if (dot.size > dot.target) dot.size = dot.target;
				if (dot.size > max) dot.size = max;
			} else {
				dot.size -= decay * 50;			
			}
			
			if (dot.size <= min)
			{
				dot.active = false;
				dot.size = min;
				dot.target = min;
			} else {				
				fCtx.beginPath();
				fCtx.arc(dot.x, dot.y, dot.size, 0, 2 * Math.PI, false);
				fCtx.fill();
				
				dot.target -= decay;
			}
		}
	};
	
	const draw = () =>
	{
		const { color } = config;
		
		if (renderBackground)
		{
	        bCtx.fillStyle = color.background; 
			bCtx.rect(0, 0, size.width, size.height);
			bCtx.fill();
			
			bCtx.fillStyle = color.inactive;
		}
		
		fCtx.clearRect(0, 0, size.width, size.height);
		fCtx.fillStyle = color.active; 
		
		createDots();
		dots.forEach((c) => c.forEach(drawDot));
		
		if (renderBackground) renderBackground = false;
		
		if (active) requestAnimationFrame(draw);
	};

	const init = () =>
	{	
		onResize();	
	    
	    window.addEventListener("mousemove", onMouseMove, { passive: true });
	    window.addEventListener("touchmove", onTouchMove, { passive: true });
		window.addEventListener("resize", onResize, { passive: true });
		
		draw();
	};
	
	setTimeout(init, 50);
	
	return () =>
	{
	    window.removeEventListener("mousemove", onMouseMove);
	    window.removeEventListener("touchmove", onTouchMove);
		window.removeEventListener("resize", onResize);
		
		active = false;
	};
};