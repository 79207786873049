import React from "react";

import { ReactComponent as TwitterLogo } from "media/logos/twitter.svg";
import { ReactComponent as CocaColaLogo } from "media/logos/cocacola.svg";
import { ReactComponent as McDonaldsLogo } from "media/logos/mcdonalds.svg";
import { ReactComponent as OlympicsLogo } from "media/logos/olympics.svg";
import NBCLogo from "media/logos/nbc.png";
import UniversalLogo from "media/logos/universal.png";

import { Grid, Item } from "components/grid";
import { SectionTitle } from "components/section";

import "./sass/clients.scss";

const logos = 
[
	{
		img: <TwitterLogo />
	},
	{
		img: <CocaColaLogo />
	},
	{
		img: <McDonaldsLogo />
	},
	{
		img: <OlympicsLogo />
	},
	{
		img: <img src={ NBCLogo } alt="NBC Logo" />
	},
	{
		img: <img src={ UniversalLogo } alt="Universal Logo" />
	}	
];

const Clients = () =>
{	
	const logoCol = { sm: { span: 7 }, lg: { span: 1 } };
		
	const logoImage = ({img }, index) =>
	{
		return (
		<Item key={ index } span={ 12 } grow={ true } config={ logoCol }>
			<div className="client-logo">
				{ img }
			</div>
		</Item>);
	};
	
	return (
	<div className="content">
		<SectionTitle subTitle="Who have you worked with?">Clients</SectionTitle>
		<Grid className="client-logos" gap="large">
			{ logos.map(logoImage) }
		</Grid>
	</div>);
};

export default Clients;
