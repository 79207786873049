import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { selectActiveSection, selectMobile } from "redux/app/selectors";

import DesktopHeader from "./desktop.jsx";
import MobileHeader from "./mobile.jsx";

import "./sass/header.scss";

const Header = () =>
{	
    const [ color, setColor ] = useState("light");
    
	const active = useSelector(selectActiveSection);
	const isMobile = useSelector(selectMobile);
	
    useEffect(() =>
    {	    
	    if (!active) return;
	    setColor(active.color === "dark" ? "light" : "dark");
    },[ active ]);
    
	const props = 
	{ 
		active: active, 
		color: color
	};
	
	return isMobile ? <MobileHeader { ...props } /> : <DesktopHeader { ...props } />;
};

export default Header;