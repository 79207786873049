import React, { useState } from "react";

import { SectionBlock } from "components/section";
import { Dots } from "components/effects";
import AutoSize from "components/autosize";
import Hero from "components/hero";

const Home = () =>
{	
	const [ parent, setParent ] = useState(false);
	
	const background = <Dots parent={ parent } />;
	const center = (
	<>
		<h1 className="headline">
			<AutoSize><strong>Page not found</strong></AutoSize>
		</h1>
	
		<p>Meep. Boop.</p>
	</>);
	
	return (
	<SectionBlock setRef={ setParent } id="lost" link="/">
		<Hero background={ background } center={ center } />
	</SectionBlock>);
};

export default Home;
