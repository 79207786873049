import React from "react";

import { Grid, Item } from "components/grid";
import { SectionTitle, SectionContent } from "components/section";

import { Chevron } from "components/logo";

import "./sass/about.scss";

const About = () =>
{
	const contentCol = { md: { span: 12 } };
	
	return (
	<>
		<div className="bkg about-effect" />
		<div className="content">
			<SectionTitle light={ true } subTitle={ <>newtype is&#8230;</> }>Just Us</SectionTitle>
			<Grid gap="default" gaps={{ md: "large" }}>			
				<Item span={ 24 } config={ contentCol }>
					<SectionContent>			
						<p>We are a collective of creators – digital designers, developers, traditional artists, musicians – most of us multi-talented. We formed <strong>newtype</strong> because we were tired of being told to water things down, to cut corners, and to purposely deliver a subpar product. If you're really good at what you do, then there’s no reason not to showcase that.</p>
						
						<p>With <strong>newtype</strong>, the work always comes first. The quality of the work that we produce is our top priority. The inside is just as beautiful as the outside because we believe that there is a level of pride that should be taken in your product. We make sure that even the smallest of details is on point, even the stuff that most people don’t see. These are skills that have been honed over a lifetime by passion and ingenuity, so every line of code is handcrafted with care and purpose.</p>
						
						<p>After the work, our second priority is fun*. If you’re not enjoying your job and the people that you’re working with, then you’re not doing it right. That's why we don't monitor hours or stare at numbers all day, and we definitely don't take ourselves seriously.</p>
					</SectionContent>
				</Item>
				
				<Item span={ 24 } config={ contentCol }>
					<SectionContent>
						
						<p>At a former job, somebody was having a conversation with the COO about the quality of the product that he was currently being asked to put out. The COO responded with: “You’re just making sandwiches, man. You’re just making sandwiches and sending them out the door.” At the next onboarding of new hires, the owner of the company is giving his spiel about the company and says, “We want you to really care about the work you’re doing here. It’s not like we’re asking you to make sandwiches or something.”</p>
						
						<p>Yikes! Uh, your COO literally told someone to make sandwiches. You can’t see it, but we’re all making stink faces.</p>
						
						<p>This is why <strong>newtype</strong> exists. We’re not making sandwiches over here. But if you really want a sandwich, we’ll make you one – and it will be the most delicious sandwich you’ve ever had.</p>
						
						<p className="small">* and also our third priority&#8230; and fourth.</p>
					</SectionContent>
				</Item>
			</Grid>
			
			<div className="watermark">
				<Chevron />
				<Chevron direction="right" />
			</div>
		</div>
	</>);
};

export default About;
