import { useEffect, useState } from "react";

import _ from "lodash";

import { useHistory, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "redux/app/actions";
import { selectSections, selectActiveSection } from "redux/app/selectors";

const visibleHeight = (element) => 
{
	const { top, height } = element.getBoundingClientRect();
	
	let visibleHeight = 0;
	
	if (top > 0)
	{
		if (top > window.innerHeight)
		{
			visibleHeight = 0;
		} else {
			visibleHeight = window.innerHeight - top;
		}				
	} else {
		visibleHeight = height + top;
	}
	
	if (visibleHeight > height) visibleHeight = height;
	if (visibleHeight < 0) visibleHeight = 0;
				
	return visibleHeight;
};

const Scroller = () =>
{
	const location = useLocation();
	const history = useHistory();
    const dispatch = useDispatch();
	
	const [ isScrolling, setIsScrolling ] = useState(false);
	const [ scrollActive, setScrollActive ] = useState(false);
	
	const sections = useSelector(selectSections);
	const active = useSelector(selectActiveSection);
	
	useEffect(() =>
	{
		if (!active || isScrolling || !scrollActive || active.link === scrollActive.link) return;
		history.replace(scrollActive.link);
		dispatch(setActiveSection(scrollActive));
	},[ history, dispatch, isScrolling, active, scrollActive ]);
	
	useEffect(() =>
	{
		if (!isScrolling || isScrolling.link !== scrollActive.link) return;
    	setIsScrolling(false);
	},[ isScrolling, scrollActive ]);
	
	useEffect(() =>
	{
		if (!Object.keys(sections).length || !active || active.link === location.pathname) return;
		
    	const activeKey = Object.keys(sections).find(key => sections[key].link === location.pathname);
		
		if (!activeKey) return;
    	
    	const section = sections[activeKey];
    	
    	setIsScrolling(section);
    	section.element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
	},[ location, sections, active ]);
	
	useEffect(() =>
	{	
		if (!Object.keys(sections).length) return;
		
		const _onScroll = () =>
		{
	    	const activeKey = Object.keys(sections).find(key => visibleHeight(sections[key].element) > 30);
			
		    if (!activeKey) return;
		    
		    const active = sections[activeKey];
		    setScrollActive(active);
		};
		
		const onScroll = _.throttle(_onScroll);
	
		window.addEventListener("scroll", onScroll, { passive: true });
		_onScroll();
		
		return () =>
		{
		    window.removeEventListener("scroll", onScroll);
		};
	},[ sections ]);
	
	return null;
};

export default Scroller;
