import React from "react";

import { SectionBlock } from "components/section";
import { Intro, Services, About, Clients, Contact } from "./components";

const Home = () =>
{		
	return (
	<>
		<SectionBlock id="intro" link="/">
			<Intro />
		</SectionBlock>
		
		<div className="section-divider-border-bottom" />
		
		<SectionBlock id="services" color="light" link="/services">
			<Services />
		</SectionBlock>
		
		<SectionBlock id="about" link="/about">
			<About />
		</SectionBlock>
		
		<SectionBlock id="clients" color="light" link="/clients">
			<Clients />
		</SectionBlock>
		
		<div className="section-divider-border-top" />
		
		<SectionBlock id="contact" link="/contact">
			<Contact />
		</SectionBlock>
	</>);
};

export default Home;
