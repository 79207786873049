import React from "react";

import { mergeClassNames } from "helpers/classname";

import "./sass/chevron.scss";

const Chevron = ({ className, direction = "left", children, ...props }) =>
{
	
	className = mergeClassNames(["nt-chevron",direction],className);

    return (<div { ...props } className={ className } />);
};

export default Chevron;
