import { useEffect } from "react";

import { useSelector } from "react-redux";
import { selectSize, selectActiveSection } from "redux/app/selectors";

import { fadeEffect } from "./scripts/fade.js"
import "./sass/fade.scss";

const Fade = ({ parent, config = {}, children }) =>
{	
	const size = useSelector(selectSize);
	const active = useSelector(selectActiveSection);
	
	useEffect(() =>
	{		
		return fadeEffect(parent,config);		
	},[ parent, config, size, active ]);
	
	return children;
};

export default Fade;