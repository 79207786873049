import React, { useRef, useState, useEffect } from "react";

import { NavLink, useLocation } from "react-router-dom";
import { matchPath } from "react-router";

import { useSelector } from "react-redux";
import { selectMobile, selectLoaded } from "redux/app/selectors";

import { links } from "routes";

const MainNavigation = ({ open, ...props }) =>
{
    const location = useLocation();
    
    const [ wrapperWidth, setWrapperWidth ] = useState(false);
    
	const navWrapper = useRef();
	const linksWrapper = useRef();
	
	const isMobile = useSelector(selectMobile);
	const loaded = useSelector(selectLoaded);
    
    useEffect(() =>
    {
		const links = linksWrapper.current;
		
		if (!loaded || !links || isMobile) return;
		
		let { width } = links.getBoundingClientRect();
		width = Math.ceil(width) + 1;
		
		links.style.width = width + "px";
		setWrapperWidth(width);
    },[ loaded, linksWrapper, isMobile ]);
    
    useEffect(() =>
    {
		const nav = navWrapper.current;
		
	    if (!wrapperWidth || !nav) return;
		
		const width = open ? wrapperWidth : 0;
		
		nav.style.width = width + "px";
    },[ wrapperWidth, navWrapper, open ]);

    const isActive = (route) =>
    {
        return matchPath(location.pathname,route);
    };

    const link = (route) =>
    {
        const path = Array.isArray(route.path) ? route.path[0] : route.path;

        return (
        <li key={ path }>
            <NavLink className="link" to={ path } isActive={ () => isActive(route) } activeClassName="active">
                { route.link }
            </NavLink>
        </li>);
    };

	return (
	<nav ref={ navWrapper } { ...props }>
		<ul ref={ linksWrapper } className="links">
			{ links.map(link) }
		</ul>
	</nav>);
};

export default MainNavigation;
