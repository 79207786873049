import React from "react";

import { NavLink } from "react-router-dom";

import { mergeClassNames } from "helpers/classname";

import "./sass/button.scss";

const Button = ({ className, path = "#/", type = "text", children, ...props }) =>
{
	className = mergeClassNames("nt-button",className);
	
	const btnClassName = mergeClassNames("btn",type);
	let btn;
	
	switch (type)
	{
		case "link":
			btn = <NavLink className={ btnClassName } to={ path }>{ children }</NavLink>;
			break;
		case "submit":
			btn = <button className={ btnClassName }>{ children }</button>;
			break;
		default: 
			btn = <div className={ btnClassName }>{ children }</div>;
			break;
	}

    return (
	<div { ...props } className={ className }>
		{ btn }  
    </div>);
};

export default Button;
