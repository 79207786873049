import { useEffect, useState } from "react";

import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { setSize } from "redux/app/actions";
import { selectSize } from "redux/app/selectors";

const sizes = { xs: 299, sm: 600, md: 900, lg: 1200, xl: 1800 };

const Size = () =>
{
    const dispatch = useDispatch();
    
	const size = useSelector(selectSize);
	
	const [ newSize, setNewSize ] = useState(false);
	
	useEffect(() =>
	{
		if (newSize !== size) dispatch(setSize(newSize));		
	},[ dispatch, newSize, size ]);
	
	useEffect(() =>
	{
		const _onResize = () =>
		{
			const width = window.innerWidth;
			const size = Object.keys(sizes).reverse().find((key) => width >= sizes[key]) || "xs";			
			setNewSize(size);
		};
		
		const onResize = _.throttle(_onResize);
	
		window.addEventListener("resize", onResize, { passive: true });
		_onResize();
		
		return () =>
		{
		    window.removeEventListener("resize", onResize);
		};		
	},[]);
	
	return null;
};

export default Size;
