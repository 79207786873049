export const setLoaded = (loaded) => (
{
    type: "SET_LOADED",
    payload: loaded
});

export const setSize = (size) => (
{
    type: "SET_SIZE",
    payload: size
});

export const setSection = (section) =>(
{
    type: "SET_SECTION",
    payload: section
});

export const setActiveSection = (section) =>(
{
    type: "SET_ACTIVE_SECTION",
    payload: section
});