import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedinIn, faInstagram } from "@fortawesome/free-brands-svg-icons";

import "./sass/footer.scss";

const now = new Date();
const year = now.getFullYear();

const SocialLink = ({ icon, link = "#/" }) =>
{
	return (
	<a className="social-link" href={ link } target="_blank" rel="noreferrer">
		<em className="d">
			{ icon }
			<em className="h">{ icon }</em>
		</em>
	</a>);
	
};
	
const Footer = () =>
{
	
	return (
	<div className="footer">
		<p>
			<SocialLink icon={ <FontAwesomeIcon icon={ faLinkedinIn } /> } link="https://www.linkedin.com/company/newtype-llc" />
			<SocialLink icon={ <FontAwesomeIcon icon={ faTwitter } /> } link="https://twitter.com/newtype" />
			<SocialLink icon={ <FontAwesomeIcon icon={ faInstagram } /> } link="https://www.instagram.com/newtypeninja/" />
		</p>
		<p className="fs-s">
			&copy; Copyright { year } newtype LLC (kickin’ ass since 2012). <span className="nobr">All rights reserved</span>.
		</p>
	</div>);
};

export default Footer;
