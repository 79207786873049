import React from "react";

import { mergeClassNames } from "helpers/classname";

import "./sass/content.scss";

const SectionContent = ({ className, children, ...props }) =>
{
	className = mergeClassNames("section-content",className);

    return (
	<div { ...props } className={ className }>
		{ children }
    </div>);
};

export default SectionContent;
