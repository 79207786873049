import React, { useState, useEffect } from "react";

import { mergeClassNames } from "helpers/classname";

import AutoSize from "components/autosize";
import MainNavigation from "./navigation.jsx";
import { Chevron } from "components/logo";

const DesktopHeader = ({ color }) =>
{	
	const [ init, setInit ] = useState(false);
	const [ open, setOpen ] = useState(true);
    
    useEffect(() =>
    {
	    if (init || !open) return;
	    
	    const doInit = () => 
	    {
		    setInit(true);
		    setOpen(false);
	    };
	    
	    const initTimeout = setTimeout(doInit,2000);
	    return () => clearTimeout(initTimeout);
    },[ init, open ]);
    
	
	const doOpen = () => setOpen(true);
	const doClose = () => setOpen(false);
	
	const className = mergeClassNames("logo-menu", [color, open ? "open" : "closed"]);
	
	return (
	<header className="header">
		<div className={ className } onMouseLeave={ doClose } onMouseOver={ doOpen }>
			<Chevron />
			<MainNavigation open={ open } />
			<Chevron direction="right" />

			<div className="logo-text">
				<AutoSize>
					newtype
				</AutoSize>
			</div>
		</div>
	</header>);
};

export default DesktopHeader;