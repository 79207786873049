import React from "react";

import { mergeClassNames } from "helpers/classname";

import "./sass/title.scss";

const headings = { h1: "h1", h2: "h2", h3: "h3", h4: "h4", h5: "h5", h6: "h6" };

const SectionTitle = ({ className, light = false, subTitle = false, type = "h2", children, ...props }) =>
{
	const titleClass = ["section-title"];
	
	if (light) titleClass.push("light");
	
	className = mergeClassNames(titleClass,className);

    return (
	<div { ...props } className={ className }>
		{ subTitle ? <span className="sub-title">{ subTitle }</span> : null }
		{ React.createElement(headings[type] || headings.h1, { className: "title" }, children) }
    </div>);
};

export default SectionTitle;
